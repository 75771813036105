.project {
    padding: 32px 42px;
    transition: 0.25s cubic-bezier(.17, .67, .83, .67);

    .project-img {
        width: 100%;
        margin-bottom: 30px;

        display: flex;
        flex-direction: row;
        justify-content: right;
        flex-wrap: wrap;

        background-repeat: no-repeat;
        background-size: cover;
        background-position-x: center;

        /* .expandBtn {
            display: none;
            width: 100%;
            height: fit-content;
            padding: 10px;
            justify-content: right;

            .closeIcon{
                width: 24px;
                height: 24px;
                cursor: pointer;
            }
        } */

        .smIcons {
            width: fit-content;
            height: calc(100% - 36px);
            padding: 10px;
            display: flex;
            flex-direction: column;

            .icon {
                width: 24px;
                height: 24px;
                background: rgba(0, 0, 0, 0.8);
                border: 0 solid;
                padding: 6px;
                border-radius: 20px;
                transition: 0.1s cubic-bezier(.17, .67, .83, .67);
                cursor: pointer;

                &:hover {
                    background-color: $primary;
                }
            }
        }

        /* .titles {
            display: none;
            width: 100%;
            padding: 10px 0px;
            text-align: center;
            color: #ffffff;
            transform: translate(0, -300px);
            transition: 0.25s cubic-bezier(.17, .67, .83, .67);

            h3 {
                padding: 10px;
                line-height: 50px;
                font-size: 36px;
                background-color: rgba(0, 0, 0, 0.8);
                font-weight: 400;
            }

            p {
                font-size: 20px;
                font-weight: bold;
            }
        } */
    }

    .case-desc {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        .titles {
            width: 100%;
            padding: 10px 0px;
            text-align: center;
            color: $primary;

            h3 {
                padding: 10px;
                line-height: 50px;
                font-size: 30px;
                background-color: rgba(0, 0, 0, 0.8);
                font-weight: 500;
            }

            p {
                font-size: 20px;
                font-weight: bold;
            }
        }

        .desc-icons {
            width: 100%;
            padding: 1rem;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 16px;
            flex-wrap: wrap;

            .icon {
                width: 48px;
                height: 48px;
            }
        }

        button {
            width: 100%;
            min-width: 161px;
            max-width: 275px;
            height: 74px;
            margin-top: 30px;
            padding: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;

            color: $primary;
            font-size: 24px;
            font-weight: bold;

            border: 2px solid $primary;
            border-radius: 5px;

            transition: 0.1s cubic-bezier(.17, .67, .83, .67);

            &:hover {
                background-color: $primary;
                color: #ffffff;
            }
        }
    }
}

@media screen and (min-width: 1020px) {
    .project {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 20px;

        padding:  10px;
        padding-bottom: 60px;

        .project-img {
            width: 100%;

            /* .expandBtn {
                display: flex;
            } */

            .smIcons {
                .icon {
                    width: 24px;
                    height: 24px;
                    border-radius: 40px;
                }
            }

            /* .titles {
                display: block;

                h3 {
                    background-color: rgba(0, 0, 0, 0);
                }

                p {
                    font-size: 20px;
                    font-weight: bold;
                }
            } */
        }

        .case-desc {
            width: 100%;

            .desc-icons
            .icon {
                width: 24px;
                height: 24px;
            }
        }
    }

    /* .project {
        border: solid 0;
        border-radius: 40px;
        overflow: hidden;
        padding: 0;
        .project-img {
            margin-bottom: 0px;
            .expandBtn {
                display: flex;
            }

            .smIcons {
                .icon {
                    width: 48px;
                    height: 48px;
                    border-radius: 40px;
                }
            }

            .titles {
                display:block;
                h3 {
                    background-color: rgba(0, 0, 0, 0);
                }

                p {
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }

        .case-desc {
            height: 0px;
            overflow: hidden;
        }
    } */
}