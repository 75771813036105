.desc-icon,
.smIcons,
.skill-icon {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    padding: 1rem;

    .icon,
    i {
        width: 48px;
        height: 48px;
    }
}