/* Reset default styles */
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&family=Rubik+Mono+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap");
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Reset default font styles */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

/* Reset default list styles */
ul,
ol {
  list-style: none;
}

/* Reset default anchor styles */
a {
  text-decoration: none;
  color: inherit;
}

/* Reset default button styles */
button {
  background: none;
  border: none;
  cursor: pointer;
  font: inherit;
}

/* Reset default form element styles */
input,
textarea,
select {
  font: inherit;
}

/* Reset default image styles */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

nav {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0px;
  z-index: 10;
  padding: 23px 30px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  background-color: #000;
  color: #fff;
}
nav .logo {
  height: 100%;
  color: #f13554;
  font-size: 25px;
  line-height: 150%;
  font-family: Rubik Mono One;
  position: relative;
  overflow: hidden;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
nav .logo::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #FF7994;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}
nav .logo:hover::after {
  transform: translateX(0);
}
nav .logo:hover,
nav .logo.open:hover {
  color: #FF7994;
}
nav .logo.open {
  color: #ffffff;
}
nav .nested-menue {
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  padding: 23px 30px;
  padding-top: 80px;
  text-transform: uppercase;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: end;
  text-align: center;
  flex-direction: column;
  gap: 12px;
  background-color: #f13554;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
nav .nested-menue li {
  padding-bottom: 1rem;
}
nav .nested-menue li a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
nav .nested-menue li a * {
  padding: 0 4px;
}
nav .nested-menue li a .svg-inline--fa {
  width: 20px;
  height: 20px;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
nav .nested-menue li a .icon {
  width: 0px;
}
nav .nested-menue li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background-color: #FF7994;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}
nav .nested-menue li a:hover::after {
  transform: translateX(0);
}
nav .nested-menue li a:hover .icon {
  width: 20px;
}
nav .nested-menue .socials {
  display: flex;
  justify-content: space-between;
}
nav .nested-menue.open {
  -webkit-clip-path: circle(150% at 100% 0%);
          clip-path: circle(150% at 100% 0%);
}
nav .burger {
  width: 25px;
  height: 100%;
  cursor: pointer;
}
nav .burger .bLine1,
nav .burger .bLine2,
nav .burger .bLine3 {
  width: 25px;
  height: 4px;
  background: #f13554;
  border-radius: 2px;
  transform-origin: left;
  transition: all 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  pointer-events: none;
}
nav .burger .bLine2 {
  margin: 6.5px 0px;
}
nav .burger:hover .bLine1,
nav .burger:hover .bLine2,
nav .burger:hover .bLine3,
nav .burger.open:hover .bLine1,
nav .burger.open:hover .bLine2,
nav .burger.open:hover .bLine3 {
  background-color: #FF7994;
}
nav .burger.open .bLine1,
nav .burger.open .bLine2,
nav .burger.open .bLine3 {
  background-color: #ffffff;
}
nav .burger.open .bLine1 {
  transform: rotate(45deg) translate(2px, 0px);
}
nav .burger.open .bLine2 {
  opacity: 0;
}
nav .burger.open .bLine3 {
  transform: rotate(-45deg) translate(2px, 0);
}

@media screen and (max-width: 1439px) {
  nav .nested-menue {
    -webkit-clip-path: circle(0px at 100% 0%);
            clip-path: circle(0px at 100% 0%);
  }
}
@media screen and (min-width: 1440px) {
  nav .nested-menue {
    padding-top: 23px;
    height: 80px;
    color: #f13554;
    background-color: #000;
    flex-direction: row;
    align-items: center;
  }
  nav .nested-menue li {
    padding: 0;
  }
  nav .nested-menue .socials {
    display: none;
  }
  nav .burger {
    display: none;
  }
}
.contact-bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  z-index: 999;
  padding: 20px;
  background-color: rgba(84, 84, 84, 0.5019607843);
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-bg .container {
  width: 500px;
  height: -moz-min-content;
  height: min-content;
  padding: 2em;
  background-color: #fff;
}
.contact-bg .container .closeBtn {
  float: right;
}
.contact-bg .container .closeBtn .closeIcon {
  width: 25px;
  height: 25px;
  color: #f13554;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.contact-bg .container .closeBtn .closeIcon:hover {
  color: #FF7994;
}
.contact-bg .container form .form-group {
  margin-bottom: 1.5em;
  transition: all 0.3s;
}
.contact-bg .container form .form-label {
  font-size: 0.75em;
  color: var(--font-color);
  display: block;
  opacity: 0;
  transition: all 0.3s;
  transform: translateX(-50px);
}
.contact-bg .container form .form-control {
  box-shadow: none;
  border-radius: 0;
  border-color: #ccc;
  border-style: none none solid none;
  width: 100%;
  font-size: 1.25em;
  transition: all 0.6s;
}
.contact-bg .container form .form-control::-moz-placeholder {
  color: #aaa;
}
.contact-bg .container form .form-control::placeholder {
  color: #aaa;
}
.contact-bg .container form .form-control:focus {
  box-shadow: none;
  border-color: var(--font-hover-color);
  outline: none;
}
.contact-bg .container form .form-group:focus-within {
  transform: scale(1.1, 1.1);
}
.contact-bg .container form .form-control:invalid:focus {
  border-color: red;
}
.contact-bg .container form .form-control:valid:focus {
  border-color: green;
}
.contact-bg .container form .submit {
  color: #f13554;
  font-weight: 700;
  background: 0 0 #fff;
  border: 2px solid #f13554;
  border-radius: 5px;
  font-size: 1em;
  padding: 10px 50px;
  text-transform: uppercase;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.contact-bg .container form .submit:hover {
  border-color: #f13554;
  color: #fff;
  background-color: #f13554;
}
.contact-bg .container form textarea {
  resize: none;
}
.contact-bg .container form .focused > .form-label {
  opacity: 1;
  transform: translateX(0px);
}

.hero {
  width: 100%;
  min-height: 100vh;
  padding: 32px 42px;
  padding-top: clamp(250px, 50%, 400px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 100;
  line-height: 40px;
  color: #ffffff;
  background-image: url(/public/assets/bg2.jpg);
  background-color: #000000;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: clamp(250px, 50%, 400px) auto;
}
.hero .socials {
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  gap: 33px;
}
.hero .socials .icon {
  height: -moz-min-content;
  height: min-content;
  display: flex;
  flex-direction: column;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.hero .socials .icon a .svg-inline--fa {
  width: 24px;
  height: 24px;
}
.hero .socials .icon:hover {
  color: #f13554;
}
.hero .hero-content {
  width: 100%;
  max-width: 585px;
  height: -moz-min-content;
  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-weight: lighter;
  font-size: 20px;
}
.hero .hero-content .hero-main-text {
  width: 100%;
  height: -moz-min-content;
  height: min-content;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.hero .hero-content .hero-main-text h1 {
  font-size: 32px;
  text-transform: uppercase;
  line-height: 35px;
}
.hero .hero-content .hero-main-text h1 em {
  color: #f13554;
  font-style: normal;
}
.hero .hero-content .resume {
  display: block;
  margin-top: 16px;
  padding: 10px;
  pointer-events: all;
  cursor: pointer;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  background: #FFFFFF;
  border: 2px solid #f13554;
  box-sizing: border-box;
  border-radius: 5px;
}
.hero .hero-content .resume:hover {
  font-weight: bold;
  background-color: #f13554;
  color: #ffffff;
}
.hero .hero-content .cta {
  color: #f13554;
  padding: 10px;
  margin-top: 60px;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  background-color: #000000;
  border: 2px solid #f13554;
  border-radius: 5px;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.hero .hero-content .cta .icon {
  width: 0;
  margin-right: 10px;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.hero .hero-content .cta:hover {
  background-color: #f13554;
  color: #ffffff;
}
.hero .hero-content .cta:hover .icon {
  width: 45px;
  display: inline;
}

@media screen and (min-width: 1020px) {
  .hero {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 50px;
    padding: 125px 125px;
    padding-right: 600px;
    background-position: right center;
    background-size: clamp(250px, 50%, 500px) auto;
  }
  .hero .socials {
    display: flex;
  }
}
.desc-icon,
.smIcons,
.skill-icon {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  padding: 1rem;
}
.desc-icon .icon,
.desc-icon i,
.smIcons .icon,
.smIcons i,
.skill-icon .icon,
.skill-icon i {
  width: 48px;
  height: 48px;
}

.project-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.project {
  padding: 32px 42px;
  transition: 0.25s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.project .project-img {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: right;
  flex-wrap: wrap;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  /* .expandBtn {
      display: none;
      width: 100%;
      height: fit-content;
      padding: 10px;
      justify-content: right;

      .closeIcon{
          width: 24px;
          height: 24px;
          cursor: pointer;
      }
  } */
  /* .titles {
      display: none;
      width: 100%;
      padding: 10px 0px;
      text-align: center;
      color: #ffffff;
      transform: translate(0, -300px);
      transition: 0.25s cubic-bezier(.17, .67, .83, .67);

      h3 {
          padding: 10px;
          line-height: 50px;
          font-size: 36px;
          background-color: rgba(0, 0, 0, 0.8);
          font-weight: 400;
      }

      p {
          font-size: 20px;
          font-weight: bold;
      }
  } */
}
.project .project-img .smIcons {
  width: -moz-fit-content;
  width: fit-content;
  height: calc(100% - 36px);
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.project .project-img .smIcons .icon {
  width: 24px;
  height: 24px;
  background: rgba(0, 0, 0, 0.8);
  border: 0 solid;
  padding: 6px;
  border-radius: 20px;
  transition: 0.1s cubic-bezier(0.17, 0.67, 0.83, 0.67);
  cursor: pointer;
}
.project .project-img .smIcons .icon:hover {
  background-color: #f13554;
}
.project .case-desc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.project .case-desc .titles {
  width: 100%;
  padding: 10px 0px;
  text-align: center;
  color: #f13554;
}
.project .case-desc .titles h3 {
  padding: 10px;
  line-height: 50px;
  font-size: 30px;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: 500;
}
.project .case-desc .titles p {
  font-size: 20px;
  font-weight: bold;
}
.project .case-desc .desc-icons {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.project .case-desc .desc-icons .icon {
  width: 48px;
  height: 48px;
}
.project .case-desc button {
  width: 100%;
  min-width: 161px;
  max-width: 275px;
  height: 74px;
  margin-top: 30px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  color: #f13554;
  font-size: 24px;
  font-weight: bold;
  border: 2px solid #f13554;
  border-radius: 5px;
  transition: 0.1s cubic-bezier(0.17, 0.67, 0.83, 0.67);
}
.project .case-desc button:hover {
  background-color: #f13554;
  color: #ffffff;
}

@media screen and (min-width: 1020px) {
  .project {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
    padding-bottom: 60px;
  }
  .project .project-img {
    width: 100%;
    /* .expandBtn {
        display: flex;
    } */
    /* .titles {
        display: block;

        h3 {
            background-color: rgba(0, 0, 0, 0);
        }

        p {
            font-size: 20px;
            font-weight: bold;
        }
    } */
  }
  .project .project-img .smIcons .icon {
    width: 24px;
    height: 24px;
    border-radius: 40px;
  }
  .project .case-desc {
    width: 100%;
  }
  .project .case-desc .desc-icons .icon {
    width: 24px;
    height: 24px;
  }
  /* .project {
      border: solid 0;
      border-radius: 40px;
      overflow: hidden;
      padding: 0;
      .project-img {
          margin-bottom: 0px;
          .expandBtn {
              display: flex;
          }

          .smIcons {
              .icon {
                  width: 48px;
                  height: 48px;
                  border-radius: 40px;
              }
          }

          .titles {
              display:block;
              h3 {
                  background-color: rgba(0, 0, 0, 0);
              }

              p {
                  font-size: 20px;
                  font-weight: bold;
              }
          }
      }

      .case-desc {
          height: 0px;
          overflow: hidden;
      }
  } */
}
body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  background-color: #000000;
  color: #fff;
}

.no-scroll {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Rubik Mono One", monospace;
}

.main_content_wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

section {
  width: 100%;
  padding: 32px 42px;
}

.ps {
  background-image: url(../public/assets/icons/photoshop-svgrepo-com.svg);
}

.ae {
  background-image: url(../public/assets/icons/after-effects-svgrepo-com.svg);
}

.ai {
  background-image: url(../public/assets/icons/illustrator-svgrepo-com.svg);
}

.jest {
  background-image: url(../public/assets/icons/jest.svg);
}

.leaflet {
  background-image: url(../public/assets/icons/leaflet.svg);
}

.fa-html5 {
  color: #ff5100;
}

.fa-css3-alt {
  color: #255acc;
}

.fa-sass {
  color: #e944b7;
}

.fa-square-js {
  color: #e9bd44;
}

.fa-react {
  color: #61DBFB;
}

.fa-wordpress {
  color: #00749C;
}

.fa-font-awesome {
  color: #beddf8;
}

@media screen and (min-width: 768px) {
  body {
    padding-top: 10px;
  }
  .main_content_wrapper {
    width: 90%;
    margin: auto;
  }
}
@media screen and (min-width: 1440px) {
  body {
    padding-top: 10px;
  }
  .main_content_wrapper {
    width: 1120px;
    margin: auto;
  }
}/*# sourceMappingURL=style.css.map */