/* Reset default styles */
*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* Reset default font styles */
body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.5;
}

/* Reset default list styles */
ul,
ol {
	list-style: none;
}

/* Reset default anchor styles */
a {
	text-decoration: none;
	color: inherit;
}

/* Reset default button styles */
button {
	background: none;
	border: none;
	cursor: pointer;
	font: inherit;
}

/* Reset default form element styles */
input,
textarea,
select {
	font: inherit;
}

/* Reset default image styles */
img {
	max-width: 100%;
	height: auto;
	display: block;
}