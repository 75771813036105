.contact-bg {
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0px;
	z-index: 999;

	padding: 20px;
	background-color: #54545480;

	display: flex;
	justify-content: center;
	align-items: center;

	.container {
		width: 500px;
		height: min-content;
		padding: 2em;
		background-color: #fff;

		.closeBtn {
			float: right;

			.closeIcon {
				width: 25px;
				height: 25px;
				color: $primary; // This will set the SVG color
				transition: 0.25s cubic-bezier(.17, .67, .83, .67);

				// Ensure hover effect is applied to the button
				&:hover {
					color: $primaryLight; // Change color on hover
				}
			}
		}

		form {
			.form-group {
				margin-bottom: 1.5em;
				transition: all 0.3s;
			}

			.form-label {
				font-size: 0.75em;
				color: var(--font-color);
				display: block;
				opacity: 0;
				transition: all 0.3s;
				transform: translateX(-50px);
			}

			.form-control {
				box-shadow: none;
				border-radius: 0;
				border-color: #ccc;
				border-style: none none solid none;
				width: 100%;
				font-size: 1.25em;
				transition: all 0.6s;
			}

			.form-control::placeholder {
				color: #aaa;
			}

			.form-control:focus {
				box-shadow: none;
				border-color: var(--font-hover-color);
				outline: none;
			}

			.form-group:focus-within {
				transform: scale(1.1, 1.1);
			}

			.form-control:invalid:focus {
				border-color: red;
			}

			.form-control:valid:focus {
				border-color: green;
			}

			.submit {
				color: $primary;
				font-weight: 700;
				background: 0 0 #fff;
				border: 2px solid $primary;
				border-radius: 5px;
				font-size: 1em;
				padding: 10px 50px;
				text-transform: uppercase;
				transition: 0.25s cubic-bezier(.17, .67, .83, .67);
			}

			.submit:hover {
				border-color: $primary;
				color: #fff;
				background-color: $primary;
			}

			textarea {
				resize: none;
			}

			.focused>.form-label {
				opacity: 1;
				transform: translateX(0px);
			}
		}
	}
}