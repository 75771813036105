@import 'reset.scss';
@import 'var.scss';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,300;1,400;1,600;1,700&family=Rubik+Mono+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

@import './components/nav/nav';
@import './components/contatct/contact';
@import './components/hero/_hero.scss';
@import './components/about/about';
@import './components/case-grid/project';
@import './components/case/case';

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    background-color: #000000;
    color: #fff;
}

.no-scroll {
    overflow: hidden;
}

h1, h2, h3, h4, h5, h6{
    font-family: "Rubik Mono One", monospace;
}

.main_content_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

section{
    width: 100%;
    padding: 32px 42px;
}

.ps{
    background-image: url(../public/assets/icons/photoshop-svgrepo-com.svg);
}

.ae{
    background-image: url(../public/assets/icons/after-effects-svgrepo-com.svg);
}

.ai{
    background-image: url(../public/assets/icons/illustrator-svgrepo-com.svg);
}
.jest{
    background-image: url(../public/assets/icons/jest.svg);
}
.leaflet{
    background-image: url(../public/assets/icons/leaflet.svg);
}

.fa-html5 {
    color: #ff5100;
}

.fa-css3-alt {
    color: #255acc;
}

.fa-sass {
    color: #e944b7;
}

.fa-square-js {
    color: #e9bd44;
}

.fa-react {
    color: #61DBFB;
}

.fa-wordpress {
    color: #00749C;
}

.fa-font-awesome {
    color: #beddf8;
}

// Tablet styles
@media screen and (min-width: 768px) {
    body {
        padding-top: 10px;
    }

    .main_content_wrapper {
        width: 90%;
        margin: auto;
    }
}

// Desktop styles
@media screen and (min-width: 1440px) {
    body {
        padding-top: 10px;
    }

    .main_content_wrapper {
        width: 1120px;
        margin: auto;
    }
}