nav {
    width: 100%;
    height: 80px;
    position: fixed;
    top: 0px;
    z-index: 10;
    padding: 23px 30px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #000;
    color: #fff;

    .logo {
        height: 100%;
        color: $primary;
        font-size: $logoTxt;
        line-height: 150%;
        font-family: Rubik Mono One;
        position: relative; // Add this line
        overflow: hidden; // Add this line
        transition: 0.25s cubic-bezier(.17, .67, .83, .67);

        &::after {
            // Add this block
            content: '';
            position: absolute;
            left: 0;
            bottom: 0; // Position it at the bottom of the link
            width: 100%; // Full width
            height: 2px; // Line height
            background-color: $primaryLight; // Color of the line
            transform: translateX(-100%); // Start off-screen to the left
            transition: transform 0.3s ease; // Animation duration and easing
        }

        &:hover::after {
            // Add this block
            transform: translateX(0); // Move the line into view on hover
        }
    }

    .logo:hover,
    .logo.open:hover {
        color: $primaryLight;
    }

    .logo.open {
        color: $lightCol;
    }

    .nested-menue {
        width: 100%;
        height: fit-content;
        padding: 23px 30px;
        padding-top: 80px;

        text-transform: uppercase;

        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;

        display: flex;
        justify-content: end;
        text-align: center;
        flex-direction: column;
        gap: 12px;

        background-color: $primary;

        transition: 0.25s cubic-bezier(.17, .67, .83, .67);

        li {
            padding-bottom: 1rem;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                overflow: hidden;
                cursor: pointer;
                transition: 0.25s cubic-bezier(.17, .67, .83, .67);

                * {
                    padding: 0 4px;
                }

                .svg-inline--fa {
                    width: 20px;
                    height: 20px;
                    transition: 0.25s cubic-bezier(.17, .67, .83, .67);
                }

                .icon {
                    width: 0px;
                }

                &::after {
                    // Add this block
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0; // Position it at the bottom of the link
                    width: 100%; // Full width
                    height: 2px; // Line height
                    background-color: $primaryLight; // Color of the line
                    transform: translateX(-100%); // Start off-screen to the left
                    transition: transform 0.3s ease; // Animation duration and easing
                }

                &:hover::after {
                    // Add this block
                    transform: translateX(0); // Move the line into view on hover
                }

                &:hover {
                    .icon {
                        width: 20px;
                    }
                }
            }
        }

        .socials {
            display: flex;
            justify-content: space-between;
        }
    }

    .nested-menue.open {
        clip-path: circle(150% at 100% 0%);
    }

    .burger {
        width: 25px;
        height: 100%;
        cursor: pointer;

        .bLine1,
        .bLine2,
        .bLine3 {
            width: 25px;
            height: 4px;
            background: $primary;
            border-radius: 2px;
            transform-origin: left;
            transition: all 0.25s cubic-bezier(.17, .67, .83, .67);
            pointer-events: none;
        }

        .bLine2 {
            margin: 6.5px 0px;
        }
    }

    .burger:hover,
    .burger.open:hover {

        .bLine1,
        .bLine2,
        .bLine3 {
            background-color: $primaryLight;
        }
    }

    .burger.open {

        .bLine1,
        .bLine2,
        .bLine3 {
            background-color: $lightCol;
        }

        .bLine1 {
            transform: rotate(45deg) translate(2px, 0px);
        }

        .bLine2 {
            opacity: 0;
        }

        .bLine3 {
            transform: rotate(-45deg) translate(2px, 0);
        }
    }
}

@media screen and (max-width: 1439px) {
    nav .nested-menue {
        clip-path: circle(0px at 100% 0%);
    }
}

@media screen and (min-width: 1440px) {
    nav {
        .nested-menue {
            padding-top: 23px;
            height: 80px;
            color: $primary;
            background-color: #000;
            flex-direction: row;
            align-items: center;

            li {
                padding: 0;
            }

            .socials {
                display: none;
            }
        }

        .burger {
            display: none;
        }
    }
}