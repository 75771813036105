.hero {
    width: 100%;
    min-height: 100vh;
    padding: 32px 42px;
    padding-top: clamp(250px, 50%, 400px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 100;
    line-height: 40px;
    color: #ffffff;

    background-image: url(/public/assets/bg2.jpg);
    background-color: #000000;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: clamp(250px, 50%, 400px) auto;

    .socials {
        height: 100%;
        display: none;
        flex-direction: column;
        justify-content: center;
        gap: 33px;

        .icon {
            height: min-content;
            display: flex;
            flex-direction: column;
            transition: 0.25s cubic-bezier(.17, .67, .83, .67);

            a .svg-inline--fa {
                width: 24px;
                height: 24px;
            }
        }

        .icon:hover {
            color: $primary;
        }
    }

    .hero-content {
        width: 100%;
        max-width: 585px;
        height: min-content;

        display: flex;
        flex-direction: column;
        gap: 40px;

        font-weight: lighter;
        font-size: 20px;

        .hero-main-text {
            width: 100%;
            height: min-content;

            display: flex;
            flex-direction: column;
            gap: 20px;

            h1 {
                font-size: 32px;
                text-transform: uppercase;
                line-height: 35px;

                em {
                    color: $primary;
                    font-style: normal;
                }
            }
        }

        .resume {

            display: block;
            margin-top: 16px;
            padding: 10px;
            pointer-events: all;
            cursor: pointer;
            font-weight: bold;
            font-size: 20px;
            text-align: center;
            background: #FFFFFF;
            border: 2px solid $primary;
            box-sizing: border-box;
            border-radius: 5px;
        }

        .resume:hover {
            font-weight: bold;
            background-color: $primary;
            color: $lightCol;
        }

        .cta {
            color: $primary;
            padding: 10px;
            margin-top: 60px;
            font-weight: bold;
            font-size: 20px;
            text-align: center;
            background-color: #000000;
            border: 2px solid $primary;
            border-radius: 5px;
            transition: 0.25s cubic-bezier(.17, .67, .83, .67);

            .icon {
                width: 0;
                margin-right: 10px;
                transition: 0.25s cubic-bezier(.17, .67, .83, .67);
            }

            &:hover {
                background-color: $primary;
                color: #ffffff;

                .icon {
                    width: 45px;
                    display: inline;
                }
            }
        }
    }
}

@media screen and (min-width: 1020px) {
    .hero {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 50px;

        padding: 125px 125px;
        padding-right: 600px;

        background-position: right center;
        background-size: clamp(250px, 50%, 500px) auto;

        .socials {
            display: flex;
        }
    }
}