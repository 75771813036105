//                  pallete
// Body
$bodyBgc: #ededed;

// chosen color
$primary: #f13554;
$primaryLight: #FF7994;

// Light theme
$darkCol: #000000;

// Dark theme
$lightCol: #ffffff;
$darkHeroTxtCol: rgba(255, 255, 255, 0.15);

// extra
$accessibility: #3b66c8;

//                  text sizes
// header
$title: 24px;
$subTitle: 20px;
$cardTitle: 24px;
$cardSubtitle: 20px;

// body text
$bodyTxt: 16px;

// Logo text
$logoTxt: 25px;
$logoTxtCell: 20px;

// Nav Text sizes
$navBarTxt: 16px;
$navBoxTxt: 25px;

// Hero text
$heroBgTxt: 90px;
$heroBgTxtCell: 30px;
$heroMainTxt: 24px;